import React,{useEffect,useState} from 'react'
import Headers from '../components/Header';
import {Link,useNavigate} from 'react-router-dom';
import {Row,Col} from 'react-bootstrap'
import DentalIamage from '../Images/dental.jpg'
import Ent from '../Images/Ent.jpg'
import Diagnostic from '../Images/Diagnostic.jpg'
import Demo from '../Images/Demo.jpg'
import Fertility from '../Images/Fertility.png'
import healthcare from '../Images/healthcare.jpeg'
import {Zoom,Fade} from 'react-reveal';
import { IoChevronBackCircleOutline } from "react-icons/io5";

const Selectsubserives = () => {    

  const [selectedSubService, setSelectedSubService] = useState('');
  const navigate = useNavigate();

  const handleSubServiceSelection = (subService) => {
    sessionStorage.setItem('selectedSubService', subService);
    setSelectedSubService(subService);
    navigate('/finalform');
  };
  
  return (
    <div>
      <Headers/>
      <div style={{backgroundColor:'#E6EDF3',height: '85vh', width: '100vw', margin: '0',padding: '0'}}>
      <div className="container py-3">
        <div className='formBoxsh'>
          <div className='container'>
            <Row>
            <Col xs={5} style={{ padding: '0px',flex: '0 0 auto',width: '41.66666667%' }} className="FormImages">
                <div style={{textAlign:'center'}}>
                  <img src={healthcare}  style={{ width: '95%',borderRadius: '10px 10px 10px 10px',marginTop:'10px',height: '445px' }}/>
                </div>
              </Col>
              <Col xs={12} md={7} style={{ padding: '0px', position: 'relative' }}>
                <div className='mainTopic'
                  style={{
                    width: '55%',
                    height: '20%',
                    borderRadius: '0px 10px 10px 0px',
                    border: '7px solid #00A0E3',
                    marginTop: '10px',
                    fontWeight: '600',
                    backgroundColor: '#00A0E3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>   
                  <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Choose your category</h5></Zoom>
                </div>
                <Link to='/selectservices'><IoChevronBackCircleOutline className='backButton' size={40} style={{position: 'absolute',right: '5%',top: '2%', color:'red'}}/></Link>
                <Fade right>
                  <div className='calenderHeight' style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                    <div className='container'>
                    <Row className='mb-4'>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                             <div onClick={() => handleSubServiceSelection('Dental')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', padding: '15px 0', borderRadius:'5px' }}>
                          <div  style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                            <img src={DentalIamage} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                         </div>
                        <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato'}}>Dental</span>
                        </div> 
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => handleSubServiceSelection('Diagnostic')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', padding: '15px 0', borderRadius:'5px'}}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                            <img src={Diagnostic} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                         </div>
                           <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Diagnostic</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => handleSubServiceSelection('Fertility')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', padding: '15px 0', borderRadius:'5px' }}>
                            <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Fertility} alt='' style={{backgroundColor:'#ffff', width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                          <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Fertility</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => handleSubServiceSelection('ENT')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', padding: '15px 0',borderRadius:'5px' }}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Ent} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                           <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>ENT</span>
                        </div>
                          </Col>

                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => handleSubServiceSelection('Dermatoglogy')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',padding: '15px 0',borderRadius:'5px' }}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Demo} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                        <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Dermatoglogy</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          {/* <div onClick={() => handleSubServiceSelection('Dental hospital')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', padding: '15px 0',borderRadius:'5px' }}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={DentalIamage} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                        <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Dental Hospital</span>
                        </div> */}
                          </Col>
                        </Row>
                    </div>
                  </div>
                </Fade>
              </Col>
             
            </Row>
          </div>
        </div>  
        </div>   
        </div>
         </div>
  )
}

export default Selectsubserives
