import React, { useState } from 'react';
import Headers from '../components/Header';
import { FaCheckCircle } from 'react-icons/fa'; // Importing checkmark icon from react-icons
import NumberFlow from '@number-flow/react'; // Use the default export
import LogoSection from './LogoSection';
import Footer from './Footer'
import Weinclude from '../components/Weinclude'
import { useNavigate } from 'react-router-dom';

const Subscription = () => {

  const navigate = useNavigate()

  const plans = [
    {
      title: 'Hybrid',
      monthlyPrice: '₹2,917',
      annualPrice: '₹7,250', // annual price for Hybrid
      billingInfo: 'Per Month (Billed annually)',
      description: 'Perfect for small businesses just starting out.',
      features: [
        '2.9% Revenue Sharing',
        'All Pro Plan Features Plus',
        'Dedicated Account Manager',
        'Custom API Integrations',
        'Onboarding and Training Support',
        'Multi-Language Support',
        'Advanced Reporting',
        'Customizable Workflows',
        'Custom API Integrations',
        'Email Support',
      ],
      buttonText: 'Choose Hybrid',
    },
    {
      title: 'Pro',
      monthlyPrice: '₹7,999',
      annualPrice: '₹15,450', // annual price for Pro
      billingInfo: 'Per Month (Billed annually)',
      description: 'Ideal for growing businesses needing more advanced features.',
      features: [
        'All Hybrid Plan Features Plus',
        'Advanced Reporting',
        'Customizable Workflows',
        'Integration with Third-Party Apps',
        'Priority Email Support',
        'Custom API Integrations',
        'Onboarding and Training Support',
        'Multi-Language Support',
        'Email Support',
      ],
      buttonText: 'Choose Pro',
      highlighted: true, // To style differently if needed
    },
    {
      title: 'Premium',
      monthlyPrice: '₹14,999',
      annualPrice: '₹21,600', // annual price for Premium
      billingInfo: 'Per Month (Billed annually)',
      description: 'Designed for large organizations requiring comprehensive solutions.',
      features: [
        'All Pro Plan Features Plus',
        'Dedicated Account Manager',
        'Custom API Integrations',
        'Onboarding and Training Support',
        'Multi-Language Support',
        'Advanced Reporting',
        'Customizable Workflows',
        'Custom API Integrations',
        'Integration with Third-Party Apps',
      ],
      buttonText: 'Choose Premium',
    },
  ];

  const [isAnnually, setIsAnnually] = useState(false);

  const togglePricing = () => {
    setIsAnnually((prev) => !prev);
  };

  // Function to remove currency symbol and convert string to number
  const getPriceValue = (price) => {
    return parseFloat(price.replace('₹', '').replace(',', '')); // Removes ₹ and commas, then converts to number
  };

  // Display the correct price based on whether it's annual or monthly
  const updatedPlans = plans.map((plan) => ({
    ...plan,
    price: isAnnually ? plan.annualPrice : plan.monthlyPrice,
  }));

  const handleRediract = ()=>{
    navigate('/signup')
  }

  return (
    <>
      <Headers />
      <div className="pricing-container">
        <h1 className="pricing-title">Plans That Fit Your Business</h1>
        <p className="pricing-subtitle">
          From startups to enterprises, find the right solution to boost your business growth.
        </p>
        <div className="pricing-toggle">
          <button className={`toggle-btn ${!isAnnually ? 'active' : ''}`} onClick={togglePricing}>
            Monthly
          </button>
          <button className={`toggle-btn ${isAnnually ? 'active' : ''}`} onClick={togglePricing}>
            Annually
          </button>
        </div>

        {/* Mapping through the plans array to render pricing cards dynamically */}
        <div className="pricing-cards">
          {updatedPlans.map((plan, index) => (
            <div
              key={index}
              className={`pricing-card ${plan.highlighted ? 'highlighted' : ''}`}>
              <div className="plan-header">
                <h2>{plan.title}</h2>
                <p className="price">
                  {/* Display the price with NumberFlow */}
                  <NumberFlow
                    value={isAnnually ? getPriceValue(plan.annualPrice) : getPriceValue(plan.monthlyPrice)}
                    duration={1000}
                    format="currency"
                  />
                  <span className="billing-info">{plan.billingInfo}</span>
                </p>
                <p className="tax-info">+ Taxes</p>
              </div>
              <ul className="feature-list">
                {plan.features.map((feature, idx) => (
                  <li key={idx}>
                    <FaCheckCircle className="icon" /> {feature}
                  </li>
                ))}
              </ul>
              <button onClick={handleRediract} className="choose-plan-button">{plan.buttonText}</button>
            </div>
          ))}
        </div>



      </div>

      <>
        <LogoSection/>
      </>
      <>
        <Weinclude/>
      </>
      <>
        <Footer/>
      </>
   
    </>
  );
};

export default Subscription;






// import React from 'react'
// import Headers from '../components/Header';

// const Subscription = () => {
//   const plans = [
//     {
//       title: 'Hybrid',
//       price: '$29/month',
//       description: 'Perfect for small businesses just starting out.',
//       features: [
//         'Employee Onboarding',
//         'Time and Attendance Tracking',
//         'Basic Payroll Processing',
//         'Employee Self-Service Portal',
//         'Standard Reporting',
//         'Email Support',
//         '50 Employee Profiles',
//         'Mobile App Access',
//       ],
//       buttonText: 'Get Started with Hybrid',
//     },
//     {
//       title: 'Pro',
//       price: '$79/month',
//       description: 'Ideal for growing businesses needing more advanced features.',
//       features: [
//         'All Basic Plan Features Plus',
//         'Performance Management',
//         'Advanced Payroll Processing with Tax Filing',
//         'Customizable Reports and Analytics',
//         'PTO and Leave Management',
//         'Integration with Third-Party Apps',
//         'Priority Email Support',
//         'Role-Based Access Control',
//       ],
//       buttonText: 'Get Started with Pro',
//       highlighted: true,
//     },
//     {
//       title: 'Premium',
//       price: '$199/month',
//       description: 'Designed for large organizations requiring comprehensive.',
//       features: [
//         'All Professional Plan Features Plus',
//         'Unlimited Employee Profiles',
//         'Dedicated Account Manager',
//         'Onboarding and Training Support',
//         'Custom Workflows and Approvals',
//         'API Access for Custom Integrations',
//         'Multi-Language and Multi-Currency Support',
//         'Advanced Compliance Management',
//       ],
//       buttonText: 'Get Started with Premium',
//     },
//   ];
//   return (
//     <>
//     <Headers/>
//     <div className="pricing-container">
//     <h1 className="pricing-title">Plans That Fit Your Business</h1>
//     <p className="pricing-subtitle">
//       From startups to enterprises, find the right solution to streamline your HR processes.
//     </p>
//     <div className="pricing-toggle">
//       <button className="toggle-btn active">Monthly</button>
//       <button className="toggle-btn">Annually</button>
//     </div>
//     <div className="pricing-cards">
//       {plans.map((plan, index) => (
//         <div
//           key={index}
//           className={`pricing-card ${plan.highlighted ? 'highlighted' : ''}`}>
//           <h2>{plan.title}</h2>
//           <h3>{plan.price}</h3>
//           <p>{plan.description}</p>
//           <ul>
//             {plan.features.map((feature, idx) => (
//               <li key={idx}>{feature}</li>
//             ))}
//           </ul>
//           <button className="action-button">{plan.buttonText}</button>

//         </div>
//       ))}
//     </div>
//   </div>
//   </>

//   );
// }

// export default Subscription

