import React, { useState, useEffect } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Header from '../components/Header';
import { Row, Col,} from 'react-bootstrap';
import Logo from '../Images/blaccloo.png';
import { IoIosTime } from 'react-icons/io';
import { GoDotFill } from 'react-icons/go';
import { IoCall } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { FcAlarmClock } from 'react-icons/fc';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import { Calendar } from 'react-modern-calendar-datepicker';
import { Fade } from 'react-reveal';
import { Calendar } from 'primereact/calendar';

const Appointment = () => {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  // const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const minimumDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };

  const handleBoxClick = (boxId) => {
    setSelectedBox(boxId);
  };
  
  const selectedService = sessionStorage.getItem('selectedService');
  const selectedSubService = sessionStorage.getItem('selectedSubService');
  const name = sessionStorage.getItem('name')
  const email = sessionStorage.getItem('email')
  const number = sessionStorage.getItem('number')
  const region = sessionStorage.getItem('region')
  const country = sessionStorage.getItem('country')
  const panIndia = sessionStorage.getItem('panIndia')
  const brand = sessionStorage.getItem('brand')
  const websiteURL = sessionStorage.getItem('websiteURL')
  const noOfLeads = sessionStorage.getItem('noOfLeads')
  const maxDays = sessionStorage.getItem('maxDays')
  const minDays = sessionStorage.getItem('minDays')

  const handleScheduleEvent = async () => {
    try {
      if (selectedDay && selectedBox) {
        setIsLoading(true); 
        const formData = new FormData();
        const day = selectedDay.getDate().toString().padStart(2, '0');
        const month = (selectedDay.getMonth() + 1).toString().padStart(2, '0');
        const year = selectedDay.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        formData.append('appointment_date', formattedDate);
        formData.append('appointment_time', selectedBox);
        formData.append('catagory', selectedService);
        formData.append('sub_catagory', selectedSubService);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('number', number);
        formData.append('continent', region);
        formData.append('country', country);
        formData.append('sub_country', panIndia);
        formData.append('brand_name', brand);
        formData.append('url', websiteURL);
        formData.append('lead_count', noOfLeads);
        formData.append('no_of_leads', noOfLeads);
        formData.append('min_days', minDays);
        formData.append('max_days', maxDays);
  
        const response = await fetch('https://euctostaging.com/lms/api/clientform/storeall', {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          console.log('Appointment scheduled successfully');
          navigate('/thankyou');
        } else {
          console.error('Failed to schedule appointment:', response.statusText);
        }
      } else {
        console.error('Please select both date and time for scheduling');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setIsLoading(false); 
    }
  };
  


  const boxStyle = (time) => ({
    border: '1px solid #D8D4D1',
    borderRadius: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: selectedBox === time ? 'blue' : '',
    color: selectedBox === time ? '#ffff' : '',
  });


  return (
    <div>
      <Header/>
      <div className='container my-3'>
        <Fade right>
        <div style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius:'10px'}}>
          <Row>
            <Col xs={12} md={4}>
              <div className='mx-4'>
                <img src={Logo} alt='logo' style={{width:'50%',marginTop:'35px', textAlign:'center'}}  />
                <hr/>
                <h3 style={{fontFamily: 'sans-serif',fontWeight: '700'}}>Lead Requirements & Discussion</h3>
                <div style={{display:'flex', marginTop:'10px'}}>
                  <IoIosTime size={25} style={{color:'#737373'}}/>
                  <p className='ms-1'>30 min</p>
                </div>
                <h6 style={{fontFamily: 'sans-serif',fontWeight: '700'}}><IoCall className='text-danger me-1'/>The purpose of this call is:</h6>
                <p><GoDotFill/> Learning about the Lead requirements and initiating a discussion.</p>
                <p><GoDotFill/> An overview of our team, our capabilities, and the processes.</p>
                <hr/>
                <h6 style={{fontFamily: 'sans-serif',fontWeight: '700'}}><FcAlarmClock className='text-danger me-1'/>The times are in Indian time:</h6>
                <p><GoDotFill/> If you need a different time, please email us at <a href="mailto:support@blaccdot.com">support@blaccdot.com</a></p>
              </div>
            </Col>
            <Col xs={12} md={5}>
              <div style={{marginTop:'10%'}}>
                <h4 style={{fontFamily: 'sans-serif',fontWeight: '700'}}>Select a Date & Time</h4>
              </div>
              {/* <Calendar
                    value={selectedDay}
                    onChange={setSelectedDay}
                    calendarClassName="responsive-calendar"
                    colorPrimary="#2E83F4"
                    minimumDate={minimumDate}
                    shouldHighlightWeekends/> */}
                        <div className="card flex justify-content-center">
            <Calendar value={selectedDay} onChange={(e) => setSelectedDay(e.value)} inline
             minDate={today}
             selectionClassName="selected-date" 
             />
        </div>
            </Col>
            <Col xs={12} md={3}>
              <div className='mx-4' style={{marginTop:'40px'}}>
                <span style={{fontSize: 'small'}}>Scroll down to view more timing</span>
                <div style={{maxHeight: '400px',overflowY: 'auto',marginTop:'10px'}}>
              <div className='selectTime mb-3' style={boxStyle('10:00 AM - 10:30 AM')} onClick={() => handleBoxClick('10:00 AM - 10:30 AM')}>
                  <p className='my-2'>10:00 AM - 10:30 AM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('11:00 AM - 11:30 AM')} onClick={() => handleBoxClick('11:00 AM - 11:30 AM')}>
                  <p className='my-2'>11:00 AM - 11:30 AM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('11:30 AM - 12:00 PM')} onClick={() => handleBoxClick('11:30 AM - 12:00 PM')}>
                  <p className='my-2'>11:30 AM - 12:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('12:00 PM - 12:30 PM')} onClick={() => handleBoxClick('12:00 PM - 12:30 PM')}>
                  <p className='my-2'>12:00 PM - 12:30 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('12:30 PM - 01:00 PM')} onClick={() => handleBoxClick('12:30 PM - 01:00 PM')}>
                  <p className='my-2'>12:30 PM - 01:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('01:00 PM - 01:30 PM')} onClick={() => handleBoxClick('01:00 PM - 01:30 PM')}>
                  <p className='my-2'>01:00 PM - 01:30 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('01:30 PM - 02:00 PM')} onClick={() => handleBoxClick('01:30 PM - 02:00 PM')}>
                  <p className='my-2'>01:30 PM - 02:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('02:00 PM - 02:30 PM')} onClick={() => handleBoxClick('02:00 PM - 02:30 PM')}>
                  <p className='my-2'>02:00 PM - 02:30 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('02:30 PM - 03:00 PM')} onClick={() => handleBoxClick('02:30 PM - 03:00 PM')}>
                  <p className='my-2'>02:30 PM - 03:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('03:00 PM - 03:30 PM')} onClick={() => handleBoxClick('03:00 PM - 03:30 PM')}>
                  <p className='my-2'>03:00 PM - 03:30 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('03:30 PM - 04:00 PM')} onClick={() => handleBoxClick('03:30 PM - 04:00 PM')}>
                  <p className='my-2'>03:30 PM - 04:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('04:00 PM - 04:30 PM')} onClick={() => handleBoxClick('04:00 PM - 04:30 PM')}>
                  <p className='my-2'>04:00 PM - 04:30 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('04:30 PM - 05:00 PM')} onClick={() => handleBoxClick('04:30 PM - 05:00 PM')}>
                  <p className='my-2'>04:30 PM - 05:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('05:00 PM - 05:30 PM')} onClick={() => handleBoxClick('05:00 PM - 05:30 PM')}>
                  <p className='my-2'>05:00 PM - 05:30 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('05:30 PM - 06:00 PM')} onClick={() => handleBoxClick('05:30 PM - 06:00 PM')}>
                  <p className='my-2'>05:30 PM - 06:00 PM</p>
                </div>
                <div className='selectTime mb-3' style={boxStyle('06:00 PM - 06:30 PM')} onClick={() => handleBoxClick('06:00 PM - 06:30 PM')}>
                  <p className='my-2'>06:00 PM - 06:30 PM</p>
                </div>
                </div>
                <div className='mb-3 text-center'>
                <button type="submit"  className='button-87' disabled={isLoading} onClick={handleScheduleEvent}>
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  <>Schedule Event</>
                )}
              </button>

            </div>
              </div>
            </Col>
          </Row>
        </div>
        </Fade>
      </div>
    </div>
  )
}

export default Appointment;