import React from "react";
import Header from "../components/Header";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import { Row, Col } from "react-bootstrap";
import Blog1 from "../Images/Blog1.jpg";
import Blog2 from '../Images/blog2.jpg'
import Blog3 from '../Images/blog3.jpg';
import Blog4 from '../Images/blog4.jpg';
import Blog5 from '../Images/blog5.jpg';
import Footer from '../Screen/Footer'

const Blogs = () => {
  return (
    <div>
      <Header />
      <div className="container my-5">
        <Row>
          <Col md={9}>
            <Row>
              <Col md={12}>
             <a href="/10-lean-strategies-to-boost-e-commerce-roas" style={{textDecoration:'none'}}>  
              <Card sx={{ maxWidth: "100%" }}>
                  <CardActionArea>
                    <CardMedia component="img" height="250" image={Blog1} alt="green iguana"/>
                    <CardContent>
                      <p style={{ fontSize: "smaller", color: "#676767" }}>
                        Publish Data | 25 November 2024
                      </p>
                      <Typography gutterBottom variant="h5" component="div">
                        10 Lean Strategies to Boost E-commerce ROAS | Blaccdot
                        Guide
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }} >
                        Discover 10 lean strategies to enhance your e-commerce
                        ROAS and drive sales. A must-read guide for business
                        owners struggling with sales.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card></a>
              </Col>
              <Col md={6} className="mt-4">
              <Card sx={{ maxWidth: '100%' }}>
                    <CardActionArea>
                        <CardMedia component="img" height="100%" image={Blog2} alt="green iguana"/>
                        <CardContent>
                        <p style={{ fontSize: "smaller", color: "#676767" }}>
                        Publish Data | 25 November 2024
                      </p>
                        <Typography gutterBottom variant="h6" component="div">
                        From Clicks to Visits: Lean Strategies to Convert Real Estate Leads into Property Viewings
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Struggling to turn real estate leads into property viewings? Discover lean strategies to optimize your lead conversion process and drive more visits to your properties.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    </Card>
              </Col>
              <Col md={6} className="mt-4">
                    <Card sx={{ maxWidth: '100%' }}>
                    <CardActionArea>
                        <CardMedia component="img" height="100%" image={Blog4} alt="green iguana"/>
                        <CardContent>
                        <p style={{ fontSize: "smaller", color: "#676767" }}>
                        Publish Data | 25 November 2024
                      </p>
                        <Typography gutterBottom variant="h6" component="div">
                        7 Game-Changing Tips to Attract High-Quality Leads Using Meta Ads
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Meta Ads is a great platform for generating high-quality leads. In this blog, we'll explore seven tips to help you attract top-notch leads using Meta Ads. You'll also learn why focusing on lead quality.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    </Card>
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <h5 style={{ color: "#1C3046", marginBottom: "20px" }}>
              Pick your favorite
            </h5>
            <div className="mb-4 blogSidelist">
              <span style={{ color: "#2763b1", fontSize: "smaller" }}>
                LEARN
              </span>
              <h6 style={{ color: "#554f4f" }}>
                10 Lean Strategies to Boost E-commerce ROAS | Blaccdot Guide
              </h6>
            </div>
            <div className="mb-4 blogSidelist">
              <span style={{ color: "#2763b1", fontSize: "smaller" }}>
                LEARN
              </span>
              <h6 style={{ color: "#554f4f" }}>
                From Clicks to Visits: Lean Strategies to Convert Real Estate
                Leads into Property Viewings
              </h6>
            </div>
            <div className="mb-4 blogSidelist">
              <span style={{ color: "#2763b1", fontSize: "smaller" }}>
                INFORMATION
              </span>
              <h6 style={{ color: "#554f4f" }}>
                11 Proven Ways to Attract High-Quality Leads Using Google Ads
                (2024)
              </h6>
            </div>
            <div className="mb-4 blogSidelist">
              <span style={{ color: "#2763b1", fontSize: "smaller" }}>
                INFORMATION
              </span>
              <h6 style={{ color: "#554f4f" }}>
                7 Game-Changing Tips to Attract High-Quality Leads Using Meta
                Ads
              </h6>
            </div>
            <div className="mb-4 blogSidelist">
              <span style={{ color: "#2763b1", fontSize: "smaller" }}>
                HOW TO
              </span>
              <h6 style={{ color: "#554f4f" }}>
                How to Generate Leads on a Zero Marketing Budget: B2C and B2B
                Strategies
              </h6>
            </div>
          </Col>
        </Row>

        <div className="mt-5">
        <Row>
          <Col md={4}>
          <Card sx={{ maxWidth: '100%' }}>
                    <CardActionArea>
                        <CardMedia component="img" height="100%" image={Blog3} alt="green iguana"/>
                        <CardContent>
                        <p style={{ fontSize: "smaller", color: "#676767" }}>
                        Publish Data | 25 November 2024
                      </p>
                        <Typography gutterBottom variant="h6" component="div">
                        11 Proven Ways to Attract High-Quality Leads Using Google Ads (2024)
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Google Ads is the best way to attract High-converting leads that drive business growth. To ensure your ads reach potential customers, it's crucial to use targeted strategies.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    </Card>
          </Col>
          <Col md={4}>
          <Card sx={{ maxWidth: '100%' }}>
                    <CardActionArea>
                        <CardMedia component="img" height="100%" image={Blog5} alt="green iguana"/>
                        <CardContent>
                        <p style={{ fontSize: "smaller", color: "#676767" }}>
                        Publish Data | 25 November 2024
                      </p>
                        <Typography gutterBottom variant="h6" component="div">
                        How to Generate Leads on a Zero Marketing Budget: B2C and B2B Strategies
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Generating leads is important for any business. There are effective ways to attract potential customers and clients without spending money. In this guide, we'll look at lead generation
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    </Card>
          </Col>
          <Col md={4}>
          <Card sx={{ maxWidth: '100%' }}>
                    <CardActionArea>
                        <CardMedia component="img" height="100%" image={Blog1} alt="green iguana"/>
                        <CardContent>
                        <p style={{ fontSize: "smaller", color: "#676767" }}>
                        Publish Data | 25 November 2024
                      </p>
                        <Typography gutterBottom variant="h6" component="div">
                        From Clicks to Visits: Lean Strategies to Convert Real Estate Leads into Property Viewings
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Struggling to turn real estate leads into property viewings? Discover lean strategies to optimize your lead conversion process and drive more visits to your properties.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    </Card>
          </Col>
        </Row>
        </div>
      
      </div>
      <Footer/>
    </div>
  );
};

export default Blogs;
