import React from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import dashboard from '../Images/signup_image.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Payment = () => {
  return (
    <div>
<Row>
  {/* Left Column (Sticky) */}
  <Col md={7}>
    <div style={{ backgroundColor: '#F7F9FC', position: 'sticky', top: '0', height: '100vh' }}>
      <div className="pt-5">
        <div style={styles.cardContainer}>
          <h5 style={styles.quote}>
            "Few things make me feel more powerful than setting up automations in Untitled to make my life easier and more efficient."
          </h5>
          <p style={styles.author}>
            — Aliah Lane <br />
            <span style={styles.position}>Founder, Layers.io</span>
          </p>
        </div>
      </div>
      <div style={{ textAlign: 'end' }}>
        <img
          style={{
            width: '90%',
            objectFit: 'cover',
            borderRadius: '20px 0px 0px 20px',
            border: '3px solid #757575',
            borderRight: 'none',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
          }}
          src={dashboard}
          alt="dashboard"
        />
      </div>
    </div>
  </Col>

  {/* Right Column (Scrollable Content) */}
  <Col md={5} style={{ height: '100vh', overflowY: 'auto' }}>
    <div>
      <div className="signup-container">
        <div className="steps-navigation">
          <span className="step">
            <span className="circleProcess">1</span> Sign Up
          </span>
          <span className="separator" style={{ color: '#959595' }}>
            <ChevronRightIcon />
          </span>
          <span className="step" style={{ color: '#000' }}>
            <span className="circleProcess">2</span> Payment
          </span>
          <span className="separator" style={{ color: '#959595' }}>
            <ChevronRightIcon />
          </span>
          <span className="step" style={{ color: '#959595' }}>
            <span className="circleProcessnon">3</span> Access dashboard
          </span>
        </div>

        <div className="account-section">
          <h2>Ready to Launch?</h2>
          <p>One final step to unlock your dashboard and boost your business</p>
        </div>

        <div>
          <Form>
          <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          <AccountBalanceWalletOutlinedIcon sx={{fontSize: '20px'}}/>
        </Typography>
        <h4>Confirm before payment</h4><hr/>
        <Row>
        <Col xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Plan type:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>PREMIUM</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Company Name:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>Eucto verse</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Plan duration:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>1 Month</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Amount:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>₹ 14,350</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>GST:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>₹ 210</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Total amount:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>₹ 14,560</span>
            </div>
         </Col><hr style={{marginTop:'20px'}} />

         <a href='/appointment' style={{textDecoration:'none'}}><Button sx={{width:'100%'}} variant="contained">Pay now</Button></a>

        </Row>
      </CardContent>
    </Card>
          </Form>
        </div>
      </div>
    </div>
  </Col>
</Row>


    </div>
  )
}

const styles = {
    cardContainer: {
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '600px',
      margin: '0px auto',
      textAlign: 'start',
      fontFamily: 'Arial, sans-serif',
    },
    quote: {
      fontSize: '25px',
      color: '#333',
      marginBottom: '20px',
    },
    author: {
      fontSize: '16px',
      color: '#555',
      fontWeight: 'bold',
    },
    position: {
      fontSize: '14px',
      color: '#777',
    },
  };


export default Payment