import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Row,Col} from 'react-bootstrap';
import { GoPeople } from "react-icons/go";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
const Weinclude = () => {
  return (
    <div className='mb-5'>
      <div style={{ textAlign: 'center',padding: '40px 20px'}}>
        <h1 className="pricing-title">What included</h1>
            <p className="pricing-subtitle">
            Get 100+ feature out of the box with the world's leading customer insights hub
            </p>
        </div>
        <div className="container mb-5">
      <Row className="gy-4">
        {[
          { title: 'Smart Lead Enrichment', description: 'Automatically enrich leads with real-time data like job titles, company size, and social profiles to ensure your sales team is always well-informed.' },
          { title: 'AI-Powered Lead Scoring', description: 'Identify your most valuable leads instantly with AI-driven scoring that ranks leads based on engagement, behavior, and likelihood to convert.' },
          { title: 'Advanced Behavioral Tracking', description: 'Track every interaction, from website visits to email opens, and gain actionable insights to optimize your lead conversion strategies.' },
          { title: 'Customizable Sales Funnels & Pipelines', description: 'Design and optimize your own sales pipelines with intuitive drag-and-drop tools tailored to your unique business needs.' },
          { title: 'Built-in CRM & Seamless Integrations', description: 'Manage your leads effortlessly with an integrated CRM, or connect with popular tools like Salesforce, HubSpot, and Slack for a smooth workflow.' },
          { title: 'Automated Multi-Channel Outreach', description: 'Seamlessly engage leads across email, SMS, social media, and more with personalized, automated outreach campaigns.' },
        ].map((item, index) => (
          <Col key={index} md={4} className="d-flex">
            <Card sx={{ minWidth: 275, flex: 1 }}>
              <CardContent>
                <div>
                  <AutoAwesomeOutlinedIcon style={{ color: '#2B78DF' }} size={30} />
                </div>
                <div className="mt-3">
                  <h5>{item.title}</h5>
                  <span style={{ color: '#7c7878' }}>{item.description}</span>
                </div>
              </CardContent>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
    </div>
  )
}

export default Weinclude