import React from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import dashboard from '../Images/signup_image.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useNavigate} from 'react-router-dom'
const Signup = () => {

    const navigate = useNavigate()
    
    const handleRediract = ()=>{
        navigate('/payment')
    }


  return (
    <div>
<Row>
  {/* Left Column (Sticky) */}
  <Col md={7}>
    <div style={{ backgroundColor: '#F7F9FC', position: 'sticky', top: '0', height: '100vh' }}>
      <div className="pt-5">
        <div style={styles.cardContainer}>
          <h5 style={styles.quote}>
            "Few things make me feel more powerful than setting up automations in Untitled to make my life easier and more efficient."
          </h5>
          <p style={styles.author}>
            — Aliah Lane <br />
            <span style={styles.position}>Founder, Layers.io</span>
          </p>
        </div>
      </div>
      <div style={{ textAlign: 'end' }}>
        <img
          style={{
            width: '90%',
            objectFit: 'cover',
            borderRadius: '20px 0px 0px 20px',
            border: '3px solid #757575',
            borderRight: 'none',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
          }}
          src={dashboard}
          alt="dashboard"
        />
      </div>
    </div>
  </Col>

  {/* Right Column (Scrollable Content) */}
  <Col md={5} style={{ height: '100vh', overflowY: 'auto' }}>
    <div>
      <div className="signup-container">
        <div className="steps-navigation">
          <span className="step active">
            <span className="circleProcess">1</span> Sign Up
          </span>
          <span className="separator" style={{ color: '#959595' }}>
            <ChevronRightIcon />
          </span>
          <span className="step" style={{ color: '#959595' }}>
            <span className="circleProcessnon">2</span> Payment
          </span>
          <span className="separator" style={{ color: '#959595' }}>
            <ChevronRightIcon />
          </span>
          <span className="step" style={{ color: '#959595' }}>
            <span className="circleProcessnon">3</span> Access dashboard
          </span>
        </div>

        <div className="account-section">
          <h2>Create your account</h2>
          <p>Increase eCommerce revenue by 15-30% with conversational commerce</p>
        </div>

        <div>
          <Form>
            <TextField sx={{ width: '100%' }} label="Full Name" variant="standard" />
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="Email Address" variant="standard" />
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="Phone Number" variant="standard" />
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="Company Name" variant="standard" />
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="Team Strength" variant="standard" />
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="Company Location" variant="standard" />
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{ marginTop: '10px' }} components={['DatePicker']}>
                    <DatePicker sx={{width: '100%'}} label="Company stated data" />
                </DemoContainer>
                </LocalizationProvider>
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="GMB profile URL" variant="standard" />
            <TextField sx={{ width: '100%', marginTop: '20px' }} label="Business Mail ID" variant="standard" />
            <div>
            <Button onClick={handleRediract} sx={{marginTop:'20px',width:'100%'}} variant="contained">signup</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </Col>
</Row>


    </div>
  )
}

const styles = {
    cardContainer: {
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '600px',
      margin: '0px auto',
      textAlign: 'start',
      fontFamily: 'Arial, sans-serif',
    },
    quote: {
      fontSize: '25px',
      color: '#333',
      marginBottom: '20px',
    },
    author: {
      fontSize: '16px',
      color: '#555',
      fontWeight: 'bold',
    },
    position: {
      fontSize: '14px',
      color: '#777',
    },
  };

export default Signup