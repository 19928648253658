import React from 'react';

// Dummy logos array (URLs can be replaced with actual logos)
const logos = [
  { id: 1, name: 'Docker', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 2, name: 'Amplitude', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 3, name: 'Atlassian', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 4, name: 'Basecamp', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 5, name: 'Docker', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 6, name: 'Amplitude', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 7, name: 'Atlassian', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 8, name: 'Basecamp', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 9, name: 'Docker', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 10, name: 'Amplitude', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 11, name: 'Atlassian', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
  { id: 12, name: 'Basecamp', url: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/ZOHO_New.png' },
];

const LogoSection = () => {
  return (
    <div className="logo-section2">
    <p className="trusted-text">More than 100+ companies trusted us</p>
    <div className="logo-scroll-container">
      {/* Duplicate logos for seamless scroll */}
      <div className="logo-container">
        {logos.concat(logos).map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo.url} alt={logo.name} className="logo-image" />
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default LogoSection;
