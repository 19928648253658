import React from 'react';

const TestimonialCard = () => {
  return (
    <div style={styles.cardContainer}>
      <h5 style={styles.quote}>
        "Few things make me feel more powerful than setting up automations in Untitled to make my life easier and more efficient."
      </h5>
      <p style={styles.author}>
        — Aliah Lane <br />
        <span style={styles.position}>Founder, Layers.io</span>
      </p>
      <div style={styles.rating}>
        {Array(5).fill('⭐').map((star, index) => (
          <span key={index} style={styles.star}>{star}</span>
        ))}
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    margin: '20px auto',
    textAlign: 'start',
    fontFamily: 'Arial, sans-serif',
  },
  quote: {
    fontSize: '25px',
    color: '#333',
    marginBottom: '20px',
  },
  author: {
    fontSize: '16px',
    color: '#555',
    fontWeight: 'bold',
  },
  position: {
    fontSize: '14px',
    color: '#777',
  },
  rating: {
    marginTop: '10px',
  },
  star: {
    color: '#ffbf00',
    fontSize: '20px',
    marginRight: '5px',
  },
};

export default TestimonialCard;
