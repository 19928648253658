import React from 'react';
import Header from '../components/Header'
const BlogHeader = () => {
  return (

   <>
     <Header/>
     <div style={styles.container}>
      <nav style={styles.breadcrumb}>
        <span style={styles.link}>Blog</span>
        <span style={styles.separator}>&gt;</span>
        <span style={styles.category}>Customer Experience</span>
      </nav>
      <h1 style={styles.title}>10 Lean Strategies to Supercharge Your E-commerce ROAS: A Blaccdot Guide</h1>
    </div>
   </>
  );
};

const styles = {
  container: {
    padding: '20px 40px',
    backgroundColor: '#fff', // Adjust as needed
    borderBottom: '1px solid #ddd', // Optional border for separation`
  },
  breadcrumb: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '10px',
  },
  link: {
    color: '#0073e6',
    cursor: 'pointer',
  },
  separator: {
    margin: '0 5px',
  },
  category: {
    color: '#333',
  },
  title: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#000',
    margin: 0,
  },
};

export default BlogHeader;
